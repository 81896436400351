import React, { Component } from "react";
import Amplify, {
  Auth,
  graphqlOperation,
  API} from "aws-amplify";
import {
  Connect,
  withAuthenticator,
  SignIn
} from "aws-amplify-react";
import {
  Grid,
  Header,
  Form,
  Message,
  Modal,
  List,
  Container,
  Divider,
  Select,
  Dropdown,
  Image,
  Table,
  Icon,
  Segment,
  Menu,
  Button
} from "semantic-ui-react";
import "semantic-ui-css/semantic.css";
import { Elements, StripeProvider } from "react-stripe-elements";
import { CardElement, injectStripe } from "react-stripe-elements";
import ReactGA from 'react-ga';

const oauth = {
  domain: "quick-text.auth.us-east-1.amazoncognito.com",
  scope: ["profile", "openid"],
  redirectSignIn: process.env.REACT_APP_redirectSignIn,
  redirectSignOut: process.env.REACT_APP_redirectSignOut,
  responseType: "code"
};

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:e21c8ad9-c11e-4e74-b5c2-9897d6973f6e",
    region: "us-east-1",
    userPoolId: "us-east-1_KmbQ7UMfs",
    userPoolWebClientId: process.env.REACT_APP_userPoolWebClientId,
    oauth: oauth
  },
  aws_appsync_graphqlEndpoint:
    "https://pdcttnifkvfftlrwhltecfimya.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
});

const createRecord = `mutation createRecord($displayName: String!, $contactName: String!,$phoneNumber: AWSPhone!){
  createRecord(displayName: $displayName, contactName: $contactName, phoneNumber: $phoneNumber, state: true) {
    userId
    contactName
    displayName
    phoneNumber
    state
  }
}`;

class CreateRecord extends React.Component {
  state = { open: false, error: false, creating: false, contactName: '', phoneNumber: '' };
  closeConfigShow = () => {
    this.setState({ open: true, error: false, creating: false });
  };
  handleChange = (e, { name, value }) => this.setState({ [name]: value, error: false });
  close = () => this.setState({ contactName: '', phoneNumber: '', open: false });
  handleSubmit = async () => {
    const { contactName, phoneNumber } = this.state;
    const objRegExp = /^[a-zA-Z0-9 ]+$/;
    const phoneRegExp = /^\+[0-9]+$/;
    if (contactName && phoneNumber && objRegExp.test(contactName) && phoneRegExp.test(phoneNumber) ) {
      try {
        this.setState({ creating: true });
        await API.graphql(
          graphqlOperation(createRecord, {
            displayName: contactName.trim().replace(/  +/g, ' '),
            contactName: contactName.trim().toLowerCase().replace(/  +/g, ' '),
            phoneNumber: phoneNumber
          })
        );
        this.setState({ contactName: '', phoneNumber: '', open: false })
      }
      catch (error) {
        this.setState({ error: true, creating: false });
      }
    } else {
      this.setState({ error: true, creating: false });
    }

  };
  render() {
    const {
      open,
      error,
      creating,
      contactName,
      phoneNumber
    } = this.state;
    return (
      <>
        <Menu.Item as='a' primary="true" onClick={this.closeConfigShow}>
          Create contact
        </Menu.Item>
        <Modal
          open={open}
          size='tiny'
          closeOnEscape={false}
          closeOnDimmerClick={false}
          onClose={this.close}
        >
          <Modal.Header>Create new contact</Modal.Header>
          <Modal.Content>
            {error && <Message negative >
              <Message.Header>Unable to save the contact</Message.Header>
              <p>Please check the contact name and phone number</p>
            </Message>}
            <Form onSubmit={this.handleSubmit} loading={creating}>
              <Form.Group>
                <Form.Input
                  label="Contact name"
                  placeholder="James 1"
                  name="contactName"
                  value={contactName}
                  onChange={this.handleChange}
                  maxLength="20"
                  required
                />
                <Form.Input
                  label="Phone number"
                  placeholder="+64221654933"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={this.handleChange}
                  maxLength="20"
                  required
                />
              </Form.Group>
              <Form.Field
              label="Tips on creating the contacts:" />
              <List as='ul'>
      <List.Item as='li' style={{ fontSize: '0.9em' }}>Use names that are easy to recognise for Alexa, try it out and see if Alexa can find the name you created, if not - dont be disappointed, just try to come up with a simpler name.</List.Item>
      <List.Item as='li' style={{ fontSize: '0.9em' }}>Alexa hears numbers as numbers, so when you are asking for "John one", Alexa will be looking for "John 1" in your contact list.</List.Item>
      <List.Item as='li' style={{ fontSize: '0.9em' }}>Phone number should be in international format with '+' in front of the country code as in the placeholder example</List.Item>
      <List.Item as='li' style={{ fontSize: '0.9em' }}>Only English letters, spaces and numbers can be used for contact names, Alexa will not understand any other special characters.</List.Item>
      </List>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleSubmit} positive content="Create" disabled={creating} />
            <Button onClick={this.close} negative content="Cancel" disabled={creating} />
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

const deleteRecord = `mutation deleteRecord($contactName: String!){
          deleteRecord(contactName: $contactName) {
            contactName
            userId
          }
        }`;

class DeleteRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = { deleting: false };
  }
  onClick = async e => {
    const contact = this.props.contact;
    this.setState({ deleting: true });
    await API.graphql(
      graphqlOperation(deleteRecord, { contactName: contact })
    );
  };
  render() {
    return (
      <div>
        <Button
          negative
          onClick={this.onClick}
          disabled={this.state.deleting}
          content="Delete"
        />
      </div>
    );
  }
}

class ContactList extends React.Component {
  Contacts() {
    if (this.props.contacts.length !== 0) {
      return (<Table unstackable basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Contact Name</Table.HeaderCell>
            <Table.HeaderCell>Phone Number</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.contacts.map(contact => (
            <Table.Row key={contact.contactName}>
              <Table.Cell>{contact.displayName}</Table.Cell>
              <Table.Cell>{contact.phoneNumber}</Table.Cell>
              <Table.Cell key={contact.contactName}><DeleteRecord contact={contact.contactName} /></Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>)
    } else {
      return (
        <List.Item>
          <List.Content>Your contact list is empty</List.Content>
        </List.Item>
      );
    }
  }
  render() {
    return (
      <Segment style={{overflow: 'auto', maxHeight: '35vh' }}>
        {this.Contacts()}
      </Segment>
    );
  }
}

const getRecords = `query {
	getRecords {
		contactName
    displayName
    phoneNumber
	}
}`;

const onRecordModify = `
  subscription onRecordModify ($userId: String){
    onRecordModify (userId: $userId){
      userId
    }
  }
`;

class ContactListLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { identityId: "" };
  }
  async componentDidMount() {
    await Auth.currentSession().then(data => {
      this.setState({ identityId: data.idToken.payload.sub });
    });
  }
  render() {
    return (
      this.state.identityId !== "" && (
        <Connect
          query={graphqlOperation(getRecords)}
          subscription={graphqlOperation(onRecordModify, {
            userId: this.state.identityId
          })}
          onSubscriptionMsg={(prev, { onRecordModify }) => {
            // console.log(onRecordModify)
            var index = prev.getRecords.findIndex(
              obj => obj.contactName === onRecordModify.contactName
            );
            // console.log('index',index)
            if (!onRecordModify.state && index !== -1) {
              prev.getRecords.splice(index, 1);
            } else if (onRecordModify.state && index !== -1){
              prev.getRecords[index] = onRecordModify;
            } else if (onRecordModify.state && index === -1){
              prev.getRecords.push(onRecordModify);
            }
            return prev;
          }}
        >
          {({ data, loading, errors }) => {
            if (loading) {
              return <div>Loading...</div>;
            }
            return <ContactList contacts={data.getRecords} />;
          }}
        </Connect>
      )
    );
  }
}

const getHistory = `query {
	getHistory {
		contactName
    displayName
    phoneNumber
    state
    message
    timestamp
	}
}`;

const deleteMessage = `mutation deleteMessage($timestamp: String!){
          deleteMessage(timestamp: $timestamp) {
            timestamp
            userId
          }
        }`;

const onHistoryEvent = `
  subscription onHistoryEvent ($userId: String){
    onHistoryEvent (userId: $userId){
      userId
    }
  }
`;

class DeleteMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { deleting: false };
  }
  onClick = async e => {
    const { timestamp } = this.props;
    this.setState({ deleting: true });
    await API.graphql(
      graphqlOperation(deleteMessage, { timestamp: timestamp })
    );
  };
  render() {
    return (
      <div>
        <Button
          negative
          onClick={this.onClick}
          disabled={this.state.deleting}
          content="Delete"
        />
      </div>
    );
  }
}

class MessageHistory extends React.Component {

constructor(props) {
  super(props);
  this.getTime = this.getTime.bind(this);
}

getTime = (time) => {
  return String(new Date(parseInt(time * 1000)).toLocaleString())
};

  Messages() {
    if (this.props.messages.length !== 0) {
      return (<Table unstackable basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Contact Name</Table.HeaderCell>
            <Table.HeaderCell>Phone Number</Table.HeaderCell>
            <Table.HeaderCell>Message</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Timestamp</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.messages.map(message => (
            <Table.Row key={message.timestamp}>
              <Table.Cell>{message.displayName}</Table.Cell>
              <Table.Cell>{message.phoneNumber}</Table.Cell>
              <Table.Cell>{message.message}</Table.Cell>
              <Table.Cell>{message.state}</Table.Cell>
              <Table.Cell>{this.getTime(message.timestamp)}</Table.Cell>
              <Table.Cell key={message.timestamp}><DeleteMessage timestamp={message.timestamp} /></Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>)
    } else {
      return (
        <List.Item>
          <List.Content>Your messages history is empty</List.Content>
        </List.Item>
      );
    }
  }
  render() {
    return (
      <Segment style={{overflow: 'auto', maxHeight: '35vh' }}>
        {this.Messages()}
      </Segment>
    );
  }
}

class MessageHistoryLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { identityId: "" };
  }
  async componentDidMount() {
    await Auth.currentSession().then(data => {
      this.setState({ identityId: data.idToken.payload.sub });
    });
  }
  render() {
    return (
      this.state.identityId !== "" && (
        <Connect
          query={graphqlOperation(getHistory)}
          subscription={graphqlOperation(onHistoryEvent, {
            userId: this.state.identityId
          })}
          onSubscriptionMsg={(prev, { onHistoryEvent }) => {
            // console.log(onHistoryEvent)
            var index = prev.getHistory.findIndex(
              obj => obj.timestamp === onHistoryEvent.timestamp
            );
            // console.log('index',index)
            if (index !== -1 && !onHistoryEvent.state) {
              prev.getHistory.splice(index, 1);
            } else if (index === -1 && onHistoryEvent.status){
              prev.getHistory.push(onHistoryEvent);
            } else if (index !== -1 && onHistoryEvent.state) {
              prev.getHistory[index].state = onHistoryEvent.state;
            }
            return prev;
          }}
        >
          {({ data, loading, errors }) => {
            if (loading) {
              return <div>Loading...</div>;
            }
            return <MessageHistory messages={data.getHistory} />;
          }}
        </Connect>
      )
    );
  }
}

const sendFeedback = `mutation sendFeedback($subjectName: String!, $messageBody: String!){
          sendFeedback(subjectName: $subjectName, messageBody: $messageBody) {
            id
          }
        }`;

const getUser = `query {
	getUser {
		credit
	}
}`;

const onSendMessage = `
  subscription onSendMessage ($userId: String){
    onSendMessage (userId: $userId){
      userId
    }
  }
`;

class Balance extends React.Component {
  constructor(props) {
    super(props);
    this.state = { identityId: "" };
  }
  async componentDidMount() {
    await Auth.currentSession().then(data => {
      this.setState({ identityId: data.idToken.payload.sub });
      ReactGA.initialize('UA-131397153-1', {
        gaOptions: {
          userId: data.idToken.payload.sub
        }
      });
      ReactGA.pageview('/quick-text');
    });
  }
  render() {
    return (
      this.state.identityId !== "" && (
        <Connect
          query={graphqlOperation(getUser)}
          subscription={graphqlOperation(onSendMessage, {
            userId: this.state.identityId
          })}
          onSubscriptionMsg={(prev, { onSendMessage }) => {
            if (onSendMessage.status) {
              prev.getUser = onSendMessage;
              return prev;
            } else if (onSendMessage.state && onSendMessage.state.indexOf('Failed') !== -1) {
              prev.getUser.credit += 1;
              return prev
            } else {
              return prev
            }
          }}
        >
          {({ data, loading, errors }) => {
            if (loading) {
              return
            }
            return (<Menu.Item>Balance: {data.getUser.credit} SMS</Menu.Item>);
          }}
        </Connect>
      )
    );
  }
}

const createCharge = `mutation createCharge($token: String!, $amount: Int!){
          createCharge(token: $token, amount: $amount) {
            userId
            credit
            status
          }
        }`;

const paymentOptions = [
  { key: '1', text: '4 SMS ($1 USD)', value: '100' },
  { key: '2', text: '10 SMS ($2 USD)', value: '200' },
  { key: '3', text: '17 SMS ($3 USD)', value: '300' },
  { key: '5', text: '30 SMS ($5 USD)', value: '500' },
  { key: '10', text: '62 SMS ($10 USD)', value: '1000' },
  { key: '20', text: '127 SMS ($20 USD)', value: '2000' },
]

const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '18px',
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};


class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = { amount: '100', success: false, processing: false, error: false, identityId: '', invalid: false };
  }
  async componentDidMount() {
    await Auth.currentSession().then(data => {
      this.setState({ identityId: data.idToken.payload.sub });
    });
  }
  close = () => {
  this.setState({ amount: '100', success: false, processing: false, error: false, invalid: false });
  ReactGA.modalview('/top-up');
  };
  handleCardChange = () => this.setState({ success: false, processing: false, error: false, invalid: false });
  handleChange = (e, { name, value }) => this.setState({ [name]: value , success: false, processing: false, error: false, invalid: false});
  handleSubmit = async () => {
    const { amount, identityId } = this.state;
    if (identityId) {
      let {token} = await this.props.stripe.createToken({name: identityId})
      if (token) {
        try {
          this.setState({ success: false, error: false, processing: true, invalid: false})
          const result = await API.graphql(
            graphqlOperation(createCharge, {
              token: token.id,
              amount: amount
            })
          );
          ReactGA.set({ userId: this.identityId });
          ReactGA.event({
            category: 'User',
            action: 'Payment success'
          });
          this.setState({ success: result.data.createCharge.status, error: !result.data.createCharge.status, processing: false, invalid: false })
        }
        catch(error){
          this.setState({ success: false, error: true, processing: false , invalid: false})
          ReactGA.set({ userId: this.identityId });
          ReactGA.event({
            category: 'User',
            action: 'Payment failed'
          });
        }
      } else {
        this.setState({success: false, error: false, invalid: true, processing: false})
        ReactGA.set({ userId: this.identityId });
        ReactGA.event({
          category: 'User',
          action: 'Card information problem'
        });
      }
    }
  };

  render(){
    const {
      amount,
      error,
      success,
      invalid,
      processing
    } = this.state;
    return (
      <Modal
      size='tiny'
      trigger={<Menu.Item as='a' primary="true">Top up</Menu.Item>}
      closeIcon
      closeOnEscape={false}
      closeOnDimmerClick={false}
      onClose={this.close}>
        <Modal.Header>Top up your balance</Modal.Header>
        <Modal.Content>
        {error && <Message negative >
          <Message.Header>Unable to process the payment.</Message.Header>
          <p>Please try again later</p>
        </Message>}
        {success && <Message positive >
          <Message.Header>Your payment has been succesfully processed!</Message.Header>
          <p>Your balance should be topped up now</p>
        </Message>}
        {invalid && <Message negative >
          <Message.Header>Invalid card information</Message.Header>
          <p>Please check that all required fields are filled in</p>
        </Message>}
        <Form loading={processing}>
          <Form.Field
            control={Select}
            options={paymentOptions}
            name="amount"
            value={amount}
            label='Amount'
            onChange={this.handleChange}
          />
          <Form.Field
          label='Card details'/>
          <Divider />
            <CardElement {...createOptions()} onChange={this.handleCardChange} disabled={this.state.processing}/>
            <Divider />
          <Form.Field
            label={<label>*Payment details are processed over SSL using <a href='https://stripe.com/docs/security/stripe' target='_blank' rel="noopener noreferrer">Stripe</a></label>}/>
          <Button onClick={this.handleSubmit} disabled={this.state.processing}>Top up</Button>
        </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

const StripeCheckout = injectStripe(CheckoutForm);

class TopUp extends Component {
  render () {
    return (
      <StripeProvider apiKey={process.env.REACT_APP_stripeProviderApiKey}>
          <Elements>
            <StripeCheckout />
          </Elements>
      </StripeProvider>
    )
  }
}

const messageOptions = [
  { key: 'h', text: 'Help', value: 'help' },
  { key: 's', text: 'Suggestion', value: 'suggestion' },
  { key: 'q', text: 'Question', value: 'question' },
]

class AppMenu extends Component {
  signOut = async () => {
    await Auth.signOut();
  };
  state = { subjectName: '', messageBody: '', invalid: false, success: false, creating: false, error: false}
  handleChange = (e, { name, value }) => this.setState({ [name]: value , invalid: false, success: false, creating: false, error: false});
  handleSubmit = async () => {
    this.setState({ invalid: false, success: false, error: false })
    const { subjectName, messageBody } = this.state;
    if (subjectName && messageBody) {
      try {
        this.setState({creating: true});
        await API.graphql(
          graphqlOperation(sendFeedback, {
            subjectName: subjectName,
            messageBody: messageBody
          })
        );
        this.setState({ subjectName: '', messageBody: '', success: true, creating: false})
      }
      catch(error){
        this.setState({subjectName: '', messageBody: '', error: true, creating: false});
      }
    } else {
      this.setState({invalid: true, creating: false})
    }
  };
render () {
  const {
    subjectName,
    messageBody,
    error,
    success,
    creating,
    invalid
  } = this.state;
  return (
    <Grid>
    <Grid.Column
  only='computer tablet'>
  <Menu color='blue' fixed='top' inverted>
    <Menu.Item header>
      <Image size='mini' src='/icon.png' style={{ marginRight: '1.5em' }} />
      Quick Text
</Menu.Item>
    <CreateRecord />
    <Menu.Item as='a' href='https://www.amazon.com/dp/B07M69FLD6' target='_blank' rel="noopener noreferrer">
      Alexa skill
    </Menu.Item>
    <TopUp />
    <Dropdown item simple text='Support'>
      <Dropdown.Menu>
      <Menu.Item as='a' href='https://quick-text.com/tos.html' target='_blank' rel="noopener noreferrer">
        Terms of Service
      </Menu.Item>
      <Menu.Item as='a' href='https://quick-text.com/privacy.html' target='_blank' rel="noopener noreferrer">
        Privacy Policy
      </Menu.Item>
        <Modal size='small' trigger={<Dropdown.Item>About</Dropdown.Item>} closeIcon>
          <Header icon='compass outline' content='About' />
          <Modal.Content scrolling>

          <Header as='h3' style={{ fontSize: '2em' }}>
  Quick Text v1.0.0
</Header>
<p style={{ fontSize: '1.33em' }}>
Quick Text application is an addition to Quick Text Alexa skill which allows users to send SMS messages to thier contacts stored under quick-text.com account.
</p>
<p style={{ fontSize: '1.33em' }}>
You don't need a mobile phone to use a Quick Text application or Alexa skill. To be able to use Quick Text Alexa skill you just need to link the skill to your quick-text.com account.
</p>
<p style={{ fontSize: '1.33em' }}>
SMS messages can be sent to any mobile number, there are no country restrictions.
</p>
   </Modal.Content>
        </Modal>
        <Modal size='tiny' trigger={<Dropdown.Item>Help</Dropdown.Item>} closeIcon>
          <Header icon='question circle' content='Help' />
          <Modal.Content scrolling>


          <Header as='h3' style={{ fontSize: '2em' }}>Create contact</Header>
          <p style={{ fontSize: '1.33em' }}>
          To create a new contact simply click "Create contact" buttion in a top menu and supply contanct name and a phone number in international format with the code. Use simple contact names that will be easy to recognize for Alexa device. Also note that numbers like "one", "two", etc. will be converted to the actual numbers by Alexa, so if you ask Alexa to send a message to "John one", Alexa will be looking for "John 1".<br></br>
          </p>

          <Header as='h3' style={{ fontSize: '2em' }}>Delete contact</Header>
          <p style={{ fontSize: '1.33em' }}>
To delete contact from your phone book simply click on "Delete" button for the contact you wish to delete. This will permanently delete the contact from your phone book.<br></br>
</p>

          <Header as='h3' style={{ fontSize: '2em' }}>Update contact</Header>
          <p style={{ fontSize: '1.33em' }}>
To update the contact from your phone book simply click "Create contact" buttion in a top menu and supply contanct name you wish to update and a new phone number, this will update existing contact record in your phone book.<br></br>
</p>

          <Header as='h3' style={{ fontSize: '2em' }}>Top up your balance</Header>
          <p style={{ fontSize: '1.33em' }}>
Unfortunatelly Sending SMS is not free that's why we had to incur a small charge per SMS message to get the Quick Text service running. With your help we will be able to pay for website hosting, domain name and Alexa skill backend infrastructure. To top up simply click "Top up" button in the top menu, select amount of messages that you want to add to your balance and enter your card details. The payment is processed over SSL using Stripe - at any point in time we don't have access or interest in accessing your card information, the only thing we store is a transaction ID which will help Stripe to resolve disputes if any.<br></br>
</p>

          <Header as='h3' style={{ fontSize: '2em' }}>Delete your account</Header>
          <p style={{ fontSize: '1.33em' }}>
To delete your account please submit a message using "Contact Us" form in a top menu or simply e-mail to support@quick-text.com.<br></br>
</p>
          </Modal.Content>
        </Modal>
        <Dropdown.Divider />
        <Modal size='small' trigger={<Dropdown.Item>Contact Us</Dropdown.Item>} closeIcon>
          <Modal.Header>Contact Us</Modal.Header>
          <Modal.Content>
          {error && <Message negative >
            <Message.Header>Unable to submit the request.</Message.Header>
            <p>Please try again later</p>
          </Message>}
          {invalid && <Message negative >
            <Message.Header>Unable to submit the request.</Message.Header>
            <p>Please check that all required fields are filled in</p>
          </Message>}
          {success && <Message positive >
            <Message.Header>Thanks! Your message has been sent.</Message.Header>
            <p>We will review the message and contact you if necessary</p>
          </Message>}
            <Form onSubmit={this.handleSubmit} loading={creating}>
              <Form.Field
                control={Select}
                options={messageOptions}
                name="subjectName"
                value={subjectName}
                label='Message Subject'
                placeholder='Message Subject'
                onChange={this.handleChange}
                required
              />
              <Form.TextArea
                maxLength="250"
                label='Message'
                placeholder='Type your message here...'
                name="messageBody"
                value={messageBody}
                onChange={this.handleChange}
                required
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleSubmit} content="Submit" disabled={creating}/>
          </Modal.Actions>
        </Modal>
      </Dropdown.Menu>
    </Dropdown>
    <Menu.Menu position='right' >
    <Balance/>
    <Menu.Item>
      <Button color='twitter' onClick={this.signOut}>Sign-out</Button>
    </Menu.Item>
    </Menu.Menu>
  </Menu>
  </Grid.Column>

  <Grid.Column
only='mobile'>
<Menu color='blue' fixed='top' inverted>
  <Menu.Item header>
    <Image size='mini' src='/icon.png' style={{ marginRight: '1.5em' }} />
    Quick Text
</Menu.Item>

  <Dropdown item simple text='Menu'>
    <Dropdown.Menu>
    <Balance/>
    <Dropdown.Divider />
    <CreateRecord />
    <TopUp />
    <Dropdown.Divider />
    <Menu.Item as='a' href='https://www.amazon.com/dp/B07M69FLD6' target='_blank' rel="noopener noreferrer">
      Alexa skill
    </Menu.Item>
    <Modal size='small' trigger={<Dropdown.Item>About</Dropdown.Item>} closeIcon>
      <Header icon='compass outline' content='About' />
      <Modal.Content scrolling>

      <Header as='h3' style={{ fontSize: '2em' }}>
Quick Text v1.0.0
</Header>
<p style={{ fontSize: '1.33em' }}>
Quick Text application is an addition to Quick Text Alexa skill which allows users to send SMS messages to thier contacts stored under quick-text.com account.
</p>
<p style={{ fontSize: '1.33em' }}>
You don't need a mobile phone to use a Quick Text application or Alexa skill. To be able to use Quick Text Alexa skill you just need to link the skill to your quick-text.com account.
</p>
<p style={{ fontSize: '1.33em' }}>
SMS messages can be sent to any mobile number, there are no country restrictions.
</p>
</Modal.Content>
    </Modal>

    <Dropdown.Divider />

    <Menu.Item as='a' href='https://quick-text.com/tos.html' target='_blank' rel="noopener noreferrer">
      Terms of Service
    </Menu.Item>
    <Menu.Item as='a' href='https://quick-text.com/privacy.html' target='_blank' rel="noopener noreferrer">
      Privacy Policy
    </Menu.Item>

      <Dropdown.Divider />
      <Modal size='tiny' trigger={<Dropdown.Item>Help</Dropdown.Item>} closeIcon>
        <Header icon='question circle' content='Help' />
        <Modal.Content scrolling>


        <Header as='h3' style={{ fontSize: '2em' }}>Create contact</Header>
        <p style={{ fontSize: '1.33em' }}>
        To create a new contact simply click "Create contact" buttion in a top menu and supply contanct name and a phone number in international format with the code. Use simple contact names that will be easy to recognize for Alexa device. Also note that numbers like "one", "two", etc. will be converted to the actual numbers by Alexa, so if you ask Alexa to send a message to "John one", Alexa will be looking for "John 1".<br></br>
        </p>

        <Header as='h3' style={{ fontSize: '2em' }}>Delete contact</Header>
        <p style={{ fontSize: '1.33em' }}>
  To delete contact from your phone book simply click on "Delete" button for the contact you wish to delete. This will permanently delete the contact from your phone book.<br></br>
  </p>

        <Header as='h3' style={{ fontSize: '2em' }}>Update contact</Header>
        <p style={{ fontSize: '1.33em' }}>
  To update the contact from your phone book simply click "Create contact" buttion in a top menu and supply contanct name you wish to update and a new phone number, this will update existing contact record in your phone book.<br></br>
  </p>

        <Header as='h3' style={{ fontSize: '2em' }}>Top up your balance</Header>
        <p style={{ fontSize: '1.33em' }}>
  Unfortunatelly Sending SMS is not free that's why we had to incur a small charge per SMS message to get the Quick Text service running. With your help we will be able to pay for website hosting, domain name and Alexa skill backend infrastructure. To top up simply click "Top up" button in the top menu, select amount of messages that you want to add to your balance and enter your card details. The payment is processed over SSL using Stripe - at any point in time we don't have access or interest in accessing your card information, the only thing we store is a transaction ID which will help Stripe to resolve disputes if any.<br></br>
  </p>

        <Header as='h3' style={{ fontSize: '2em' }}>Delete your account</Header>
        <p style={{ fontSize: '1.33em' }}>
  To delete your account please submit a message using "Contact Us" form in a top menu or simply e-mail to support@quick-text.com.<br></br>
  </p>
        </Modal.Content>
      </Modal>
      <Modal size='small' trigger={<Dropdown.Item>Contact Us</Dropdown.Item>} closeIcon>
        <Modal.Header>Contact Us</Modal.Header>
        <Modal.Content>
        {error && <Message negative >
          <Message.Header>Unable to submit the request.</Message.Header>
          <p>Please try again later</p>
        </Message>}
        {invalid && <Message negative >
          <Message.Header>Unable to submit the request.</Message.Header>
          <p>Please check that all required fields are filled in</p>
        </Message>}
        {success && <Message positive >
          <Message.Header>Thanks! Your message has been sent.</Message.Header>
          <p>We will review the message and contact you if necessary</p>
        </Message>}
          <Form onSubmit={this.handleSubmit} loading={creating}>
            <Form.Field
              control={Select}
              options={messageOptions}
              name="subjectName"
              value={subjectName}
              label='Message Subject'
              placeholder='Message Subject'
              onChange={this.handleChange}
              required
            />
            <Form.TextArea
              maxLength="250"
              label='Message'
              placeholder='Type your message here...'
              name="messageBody"
              value={messageBody}
              onChange={this.handleChange}
              required
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleSubmit} content="Submit" disabled={creating}/>
        </Modal.Actions>
      </Modal>
    </Dropdown.Menu>
  </Dropdown>
  <Menu.Menu position='right' >
  <Menu.Item>
    <Button color='twitter' onClick={this.signOut}>Sign-out</Button>
  </Menu.Item>
  </Menu.Menu>
</Menu>
</Grid.Column>


  </Grid>
  )}
}

class App extends Component {


  render() {
    return (
      <div>
      <AppMenu/>

        <Container style={{ marginTop: '7em' }}>
          <Header as="h3">
            <Icon color='blue' name="mail" />
            My Contacts
            </Header>
          <ContactListLoader />
          <Header as="h3">
            <Icon color='blue' name="history" />
            My History
            </Header>
          <MessageHistoryLoader />
        </Container>
      </div>
    );
  }
}

class MySignIn extends SignIn {

  fedLogin = () => {
    Auth.federatedSignIn({ provider: "LoginWithAmazon" });
  };

  render() {
    return (
      <div className="login-form">
        <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
        background-color: #F8F8FF !important;
      }
    `}</style>
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Segment raised>
              <Header as="h2" color="blue" textAlign="center">
                <Image src="./icon.png" /> Log-in to Quick Text
              </Header>
    <Button onClick={this.fedLogin} color='orange'>
  <Icon name='amazon' /> Sign-in with Amazon
</Button>
            </Segment>
            <Segment raised textAlign="left" style={{ fontSize: 10 }}>
            By signing-in I agree that:
            <List as='ul'>
    <List.Item as='li'>I have read and accepted Quick Text <a href="https://quick-text.com/tos.html" target='_blank' rel="noopener noreferrer">Terms of Service</a></List.Item>
    <List.Item as='li'>I have read and accepted Quick Text <a href="https://quick-text.com/privacy.html" target='_blank' rel="noopener noreferrer">Privacy Policy</a></List.Item>
    <List.Item as='li'>I have asked and received permission from the individuals I will enter as
  Contacts to send SMS messages to using Quick Text</List.Item>
    </List>
    </Segment>
    <Icon name='copyright outline'/> Quick Text 2018
          </Grid.Column>


        </Grid>
      </div>
    );
  }
}

export default props => {
  const AppComponent = withAuthenticator(App, false, [<MySignIn />]);
  return <AppComponent {...props} />;
};
